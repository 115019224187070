import * as React from "react";
import Helmet from "react-helmet";
import * as pageStyles from "./style.module.scss";
import { FaLinkedin } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

// font weights
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";

// markup
const IndexPage = () => {
  return (
    <main className={pageStyles.container}>
    <Helmet>
      <title>Chic Themes Club</title>
      <meta name="icon" href="../images/favicon.png" />
      <meta name="description" content="Chic Themes Club is a WordPress theme shop for business owners." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    </Helmet>

      <h1 className={pageStyles.heading}>
      Chic Themes Club is a WordPress theme shop for business owners.</h1>

      <p className={pageStyles.summary}>We'll be here very soon. For more information, you can email me at <a href="mailto:hello@piyawillwerth.com" title="hello@piyawillwerth.com">hello@piyawillwerth.com</a>.</p>

      <h3>
      <a className={pageStyles.icons} href="https://linkedin.com/in/piyawill">
        <FaLinkedin title="Linked In" />
      </a>
      <a className={pageStyles.icons} href="mailto:hello@piyawillwerth.com">
        <FaEnvelope title="hello@piyawillwerth.com" />
      </a>
      </h3>

    </main>
  )
}

export default IndexPage
